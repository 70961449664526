export type Colors = "gray" | "red" | "blue" | "violet" | "lime" | "amber" | "black" | "pink" | "cyan"

export const ColorsCategory: Record<Colors, string> = {
  black: "text-black",
  gray: "text-gray-600",
  red: "text-red-600",
  blue: "text-blue-600",
  violet: "text-violet-600",
  lime: "text-emerald-700",
  amber: "text-amber-600",
  pink: "text-pink-600",
  cyan: "text-cyan-600",
}

export const BgColorsCategory: Record<Colors, string> = {
  black: "bg-black",
  gray: "bg-gray-600",
  red: "bg-red-600",
  blue: "bg-blue-600",
  violet: "bg-violet-600",
  lime: "bg-emerald-600",
  amber: "bg-amber-600",
  pink: "bg-pink-600",
  cyan: "bg-cyan-600",
}